import { Lead } from '../types';

export const crmLink = (
  lead: Pick<Lead, 'client_id' | 'source_id'>,
): string => {
  if (lead.client_id === 1) {
    return `https://client14.sierrainteractivedev.com/lead-detail.aspx?id=${lead.source_id}`;
  } else if (lead.client_id === 2) {
    return `https://carolinaone2.followupboss.com/2/people/view/${lead.source_id}`;
  } else if (lead.client_id === 3) {
    return `https://pacaso.lightning.force.com/lightning/r/Lead/${lead.source_id}/view/`;
  } else if (lead.client_id === 4) {
    return `https://laughtonteam1.followupboss.com/2/people/view/${lead.source_id}`;
  } else if (lead.client_id === 5) {
    return `https://app.hubspot.com/contacts/46350142/record/0-1/${lead.source_id}`;
  } else if (lead.client_id === 6) {
    return `https://robertjslack.followupboss.com/2/people/view/${lead.source_id}`;
  } else if (lead.client_id === 7) {
    return `https://chordrealestate.followupboss.com/2/people/view/${lead.source_id}`;
  } else if (lead.client_id === 8) {
    return `https://onepathteam.followupboss.com/2/people/view/${lead.source_id}`;
  } else if (lead.client_id === 9) {
    return `https://rockwell.followupboss.com/2/people/view/${lead.source_id}`;
  } else if (lead.client_id === 10) {
    return `https://novakteam.followupboss.com/2/people/view/${lead.source_id}`;
  } else if (lead.client_id === 11) {
    return `https://buyvet1.followupboss.com/2/people/view/${lead.source_id}`;
  } else if (lead.client_id === 12) {
    return `https://client14.sierrainteractivedev.com/lead-detail.aspx?id=${lead.source_id}`;
  } else if (lead.client_id === 13) {
    return `https://theagencyre16.followupboss.com/2/people/view/${lead.source_id}`;
  } else if (lead.client_id === 14) {
    return `https://robertslackga.followupboss.com/2/people/view/${lead.source_id}`;
  } else if (lead.client_id === 15) {
    return `https://robertslack5.followupboss.com/2/people/view/${lead.source_id}`;
  } else if (lead.client_id === 16) {
    return `https://realatlas.followupboss.com/2/people/view/${lead.source_id}`;
  } else if (lead.client_id === 17) {
    return `https://frontgate.followupboss.com/2/people/view/${lead.source_id}`;
  } else if (lead.client_id === 18) {
    return `https://k2realtyteam.followupboss.com/2/people/view/${lead.source_id}`;
  } else if (lead.client_id === 19) {
    return `https://soldbycarin.followupboss.com/2/people/view/${lead.source_id}`;
  } else if (lead.client_id === 20) {
    return `https://sandersteam.followupboss.com/2/people/view/${lead.source_id}`;
  } else if (lead.client_id === 21) {
    return `https://itzsold.followupboss.com/2/people/view/${lead.source_id}`;
  } else if (lead.client_id === 22) {
    return `https://djandlindsey.followupboss.com/2/people/view/${lead.source_id}`;
  }
  return '';
};
