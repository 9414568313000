import { Button, Input, Typography } from 'antd';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Config, Recording, Task, TasksResponse } from '../../types';
const { TextArea } = Input;
const { Title, Paragraph } = Typography;

import './styles.css';

import { DatePicker, Popover, TimePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { fetchCrmLink } from '../../utils/client-utils';

dayjs.extend(utc);
dayjs.extend(timezone);

interface TaskComponentProps {
  handleTaskChange: (value: string, taskId: string, fieldName: string) => void;
  task: Task;
  formattedDueDate: string;
  date_key: string;
  config: Config;
}

const TaskComponent: React.FC<TaskComponentProps> = ({
  handleTaskChange,
  task,
  formattedDueDate,
  date_key,
  config,
}) => {
  // Log the original formattedDueDate
  console.log('Original formattedDueDate:', formattedDueDate);
  console.log('Timezone:', config.timezone);

  // Convert the formattedDueDate to the specified timezone
  const initialDate = dayjs.utc(formattedDueDate).tz(config.timezone);
  const initialTime = dayjs.utc(formattedDueDate).tz(config.timezone);

  // Log the converted dates
  console.log('Converted initialDate:', initialDate.format());
  console.log('Converted initialTime:', initialTime.format());

  const [date, setDate] = useState<Dayjs>(initialDate);
  const [time, setTime] = useState<Dayjs>(initialTime);

  const handleDateChange = (dateValue: Dayjs | null) => {
    if (dateValue) {
      setDate(dateValue);
      const newDueDate = dateValue
        // .clone()
        .hour(time.hour())
        .minute(time.minute())
        .utc();
      handleTaskChange(newDueDate.format(), task.id, date_key);
    }
  };

  const handleTimeChange = (timeValue: Dayjs | null) => {
    if (timeValue) {
      setTime(timeValue);
      const newDueDate = date
        // .clone()
        .hour(timeValue.hour())
        .minute(timeValue.minute())
        .utc();
      handleTaskChange(newDueDate.format(), task.id, date_key);
    }
  };

  return (
    <div>
      <Title level={5}>
        {date_key.replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase())}
      </Title>
      <DatePicker onChange={handleDateChange} value={date} />
      <Popover title="Currently disabled because we are not passing to CRM">
        <TimePicker
          disabled={true}
          format="HH:mm"
          onChange={handleTimeChange}
          value={time}
        />
      </Popover>
    </div>
  );
};

interface TaskReviewPageProps {
  apiUrl: string;
}

export const TaskReviewPage = ({ apiUrl }: { apiUrl: string }): JSX.Element => {
  const [recording, setRecording] = useState<Recording>();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [error, setError] = useState('');
  const [config, setConfig] = useState<Config>();
  const [crmLink, setCrmLink] = useState<string>('');

  const { recordingID, clientId } = useParams<{
    recordingID: string;
    clientId: string;
  }>();

  useEffect(() => {
    const fetchRecording = async () => {
      try {
        const url = `${apiUrl}/api/recordings/${clientId}/${recordingID}`;
        const response = await fetch(url);
        const jsonData = await response.json();

        if (jsonData && jsonData.recording) {
          // Replace all occurrences of '\n' with '<br/>'
          jsonData.recording = jsonData.recording.replace(/\n/g, '<br/>');
        }

        const crmLink = await fetchCrmLink(
          apiUrl,
          clientId,
          recording?.lead?.source_id,
        );
        if (crmLink) {
          setCrmLink(crmLink);
        }

        setRecording(jsonData);
      } catch (err) {
        console.error('An error occurred while fetching the lead:', err);
        setRecording(undefined);
        setError('Failed to load tasks. Please try again later.');
      }
    };
    fetchRecording().then().catch(console.log);
  }, [apiUrl, clientId, recordingID]);

  useEffect(() => {
    if (recording && recording.user_id) {
      fetchTasks().catch(error =>
        console.error('Failed to execute loadTasks:', error),
      );
    }
  }, [recording]);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const url = `${apiUrl}/api/configs/${clientId}`;
        const response = await fetch(url);
        const jsonData = await response.json();
        setConfig(jsonData);
      } catch (err) {
        console.error('An error occurred while fetching the config:', err);
        setConfig(undefined);
        setError('Failed to load config. Please try again later.');
      }
    };
    fetchConfig().then().catch(console.log);
  }, [recording]);

  // useEffect(() => {
  //   fetchTasks().catch(error =>
  //     console.error('Failed to execute loadTasks:', error),
  //   );
  // }, [apiUrl, clientId, recording?.user_id]);

  const updateTaskField = (taskId: string, field: string, value: string) => {
    const updatedTasks = tasks.map(task =>
      task.id === taskId ? { ...task, [field]: value, modified: true } : task,
    );
    setTasks(updatedTasks);
  };

  const handleTaskChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    taskId: string,
    field: string,
  ): void => {
    updateTaskField(taskId, field, e.target.value);
  };

  const handleTaskDateChange = (
    value: string,
    taskId: string,
    field: string,
  ): void => {
    updateTaskField(taskId, field, value);
  };

  const updateTask = async (taskId: string): Promise<void> => {
    const taskToUpdate: Task | undefined = tasks.find(
      task => task.id === taskId,
    );
    if (!taskToUpdate) {
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/api/tasks/${taskId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(taskToUpdate),
      });
      if (!response.ok) {
        throw new Error('Failed to update task');
      }

      console.log('Task updated successfully:', taskId);
      await fetchTasks();
      // Optionally, refresh tasks from the server here
    } catch (error) {
      console.error('Failed to update task:', taskId, error);
    }
  };

  const fetchTasks = async (): Promise<void> => {
    try {
      const response = await fetch(
        `${apiUrl}/api/Tasks/${clientId}/${recording?.user_id}?show_all=1`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      const data = await response.json();
      const TasksResponse = { data: data as TasksResponse };
      const fetchedTasks = TasksResponse.data.tasks
        .filter(task => task.task_type === 'call_action_items')
        .filter(task => task.source_id === recording?.id)
        .sort((a, b) => a.created_at.localeCompare(b.created_at));

      const updatedTasks = fetchedTasks.map(task => ({
        ...task,
        modified: false,
      }));
      setTasks(updatedTasks);
      console.log('fetchedTasks:', updatedTasks);
    } catch (error) {
      console.error('Failed to fetch tasks:', error);
      setError('Failed to execute task loading. Please try again later.');
    }
  };

  const postTask = async (taskId: string): Promise<void> => {
    const taskToPost: Task | undefined = tasks.find(task => task.id === taskId);
    if (!taskToPost) {
      return;
    }

    try {
      const response = await fetch(
        `${apiUrl}/api/tasks/${taskId}/post_tasks_to_crm`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(taskToPost),
        },
      );
      if (!response.ok) {
        throw new Error('Failed to post task');
      }
      await fetchTasks();
    } catch (error) {
      console.error('Failed to post task:', taskId, error);
    }
  };

  return (
    <div>
      {error && <Paragraph>Error: {error}</Paragraph>}
      <Title level={1}>Task Review Page</Title>
      <div>
        <Title level={2}>Recording</Title>
        <Paragraph>
          <a href={crmLink} rel="noopener noreferrer" target="_blank">
            CRM Link: {recording?.lead?.source_id}
          </a>
        </Paragraph>
        <div
          dangerouslySetInnerHTML={{ __html: recording?.recording || '' }}
          style={{ textAlign: 'left' }}
        ></div>
        <br />
        <Paragraph>{recording?.action_prompt_result?.summary}</Paragraph>
      </div>
      <div>
        <Title level={2}>Tasks</Title>
        {tasks.map(task => {
          return (
            <div className="task-container" key={task.id}>
              <Input
                onChange={e => handleTaskChange(e, task.id, 'title')}
                type="text"
                value={task.title}
              />
              <TextArea
                onChange={e => handleTaskChange(e, task.id, 'description')}
                value={task.description}
              />
              {config && (
                <>
                  <TaskComponent
                    config={config}
                    date_key="due_date"
                    formattedDueDate={task.due_date || ''}
                    handleTaskChange={handleTaskDateChange}
                    task={task}
                  />
                  <TaskComponent
                    config={config}
                    date_key="expired_at"
                    formattedDueDate={task.expired_at || ''}
                    handleTaskChange={handleTaskDateChange}
                    task={task}
                  />
                </>
              )}

              <Button
                disabled={task.crm_task_id != null}
                onClick={() => void updateTask(task.id)}
                type="primary"
              >
                Update
              </Button>

              <Button
                disabled={task.crm_task_id != null || task.modified}
                onClick={() => void postTask(task.id)}
                type="primary"
              >
                Post to CRM
              </Button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TaskReviewPage;
