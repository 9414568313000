import './ChatBubble.css';

interface ChatBubbleProps {
  message: string;
  isOwnMessage: boolean;
  timestamp: string;
  status?: string;
  onClick?: () => void;
}

const ChatBubble = ({
  message,
  isOwnMessage,
  timestamp,
  status,
  onClick,
}: ChatBubbleProps) => {
  return (
    <div
      className={`chat-bubble ${isOwnMessage ? 'own-message' : 'other-message'}`}
      onClick={onClick}
    >
      <pre style={{ textAlign: 'left' }}>{message}</pre>
      <div className="chat-timestamp">{timestamp.split('+')[0]}</div>
      {status && <div className="chat-timestamp">{status}</div>}
    </div>
  );
};

export default ChatBubble;
