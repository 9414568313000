import { useEffect, useState } from 'react';

export const HealthCheck = ({ apiUrl }: { apiUrl: string }): JSX.Element => {
  const [health, setHealth] = useState<string>('undefined');

  useEffect(() => {
    const fetchRecording = async () => {
      try {
        const url = `${apiUrl}/api/healthcheck`;
        const response = await fetch(url);
        const jsonData = await response.text();
        console.log(response);
        setHealth(jsonData);
      } catch (err) {
        console.error('An error occurred while fetching data:', err);
        setHealth('Healthcheck failed.');
      }
    };
    fetchRecording().then().catch(console.log);
  }, []);

  return <div>{health}</div>;
};
