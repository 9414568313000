import { useEffect, useState } from 'react';
import { Lead, TwilioMessage } from '../../types';
import {
  Button,
  Flex,
  Input,
  Modal,
  Popconfirm,
  Segmented,
  Space,
  Spin,
} from 'antd';
import ChatBubble from '../../components/ChatBubble';
import { SendOutlined } from '@ant-design/icons';
import { getLeadPrompt } from './prompts';
import TextArea from 'antd/es/input/TextArea';
import { useEvalPrompt } from '../../hooks/use-eval-prompt';
import { AlertBanner } from '../../AlertBanner';
import { crmLink } from '../../utils';
import { OUTREACH_PROMPTS } from './prompts';
import { SegmentedValue } from 'antd/es/segmented';

interface ConversationProps {
  lead: Lead;
  apiUrl: string;
  selectedPrompt: string;
  setSelectedPrompt: (prompt: string) => void;
  systemPrompt: string;
  setSystemPrompt: (prompt: string) => void;
}
export const Conversation = ({
  lead,
  apiUrl,
  selectedPrompt,
  setSelectedPrompt,
  systemPrompt,
  setSystemPrompt,
}: ConversationProps): JSX.Element => {
  const [messages, setMessages] = useState<TwilioMessage[] | undefined>(
    undefined,
  );
  const [communications, setCommunications] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [sendLoading, setSendLoading] = useState<boolean>(false);
  const [loadingComms, setLoadingComms] = useState<boolean>(true);
  const [pastCommunicationPrompt, setPastCommunicationPrompt] =
    useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [prompt, setPrompt] = useState<string>('');

  useEffect(() => {
    if (communications == null) {
      return;
    }
    setPastCommunicationPrompt(getLeadPrompt(lead, communications));
  }, [lead, communications]);

  useEffect(() => {
    if (selectedPrompt === 'Custom') {
      setPrompt(localStorage.getItem('CustomPrompt') || '');
    } else {
      setPrompt(OUTREACH_PROMPTS[selectedPrompt]?.prompt || '');
    }
  }, [selectedPrompt]);

  const fetchCommunications = async (lead: Lead) => {
    try {
      setLoadingComms(true);
      const url = `${apiUrl}/api/Communications/${lead.client_id}/${lead.id}`;
      const response = await fetch(url);
      const jsonData = await response.json();
      setCommunications(jsonData.consolidated_communications);
    } catch (err) {
      console.error('An error occurred while fetching the lead:', err);
      setCommunications(null);
    } finally {
      setLoadingComms(false);
    }
  };
  const fetctLeadMessages = async (lead: Lead) => {
    try {
      setLoading(true);
      const url = `${apiUrl}/api/messaging/${lead.client_id}/${lead.id}`;
      const response = await fetch(url);
      const jsonData = await response.json();
      setMessages(jsonData);
    } catch (err) {
      console.error('An error occurred while fetching the lead:', err);
      setMessages([]);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetctLeadMessages(lead).then().catch(console.log);
    fetchCommunications(lead).then().catch(console.log);
  }, [lead]);

  const {
    evalPrompt,
    error,
    loading: loadingEval,
    evalResponse,
    setEvalResponse,
  } = useEvalPrompt({
    apiUrl,
    roleInstructions: systemPrompt,
    prompt,
    communications: pastCommunicationPrompt,
    messages: messages?.map(m => ({
      role: m.is_lead ? 'user' : 'assistant',
      content: m.content,
    })),
    response_format: 'text',
  });

  const send = async (isLead: boolean) => {
    try {
      setSendLoading(true);
      const url = `${apiUrl}/api/messaging/send`;
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          leadId: lead.id,
          message: evalResponse,
          isLead,
        }),
      });
      if (!response.ok) {
        throw new Error(`Failed to send message ${response.text}`);
      }
      setEvalResponse('');
      await fetctLeadMessages(lead);
    } catch (err) {
      console.error('An error occurred while fetching the lead:', err);
    } finally {
      setSendLoading(false);
    }
  };

  const reset = async () => {
    try {
      setSendLoading(true);
      const url = `${apiUrl}/api/messaging/reset/${lead.id}`;
      const response = await fetch(url, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error(`Failed to reset ${response.text}`);
      }
      setEvalResponse('');
      await fetctLeadMessages(lead);
    } catch (err) {
      console.error('An error occurred while fetching the lead:', err);
    } finally {
      setSendLoading(false);
    }
  };

  const updateCustomPrompt = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSelectedPrompt('Custom');
    setPrompt(e.target.value);
    localStorage.setItem('CustomPrompt', e.target.value);
  };

  const prompt_Options = Object.entries(OUTREACH_PROMPTS).map(([key]) => ({
    label: key,
    value: key,
  }));
  prompt_Options.push({ label: 'Custom', value: 'Custom' });

  return (
    <div>
      <AlertBanner error={error} />
      <h2>
        {lead.first_name} {lead.last_name}
      </h2>
      <Spin spinning={loading || loadingComms}>
        <Flex style={{ height: '70vh', overflowY: 'auto' }} vertical>
          {messages?.map((message, index) => (
            <ChatBubble
              isOwnMessage={!message.is_lead}
              key={index}
              message={message.content}
              status={message.status}
              timestamp={message.created_at}
            />
          ))}
        </Flex>
        <Flex gap="small" justify="flex-end">
          <Button onClick={() => void reset()} type="primary">
            Reset
          </Button>
          <Button onClick={() => setIsModalVisible(true)} type="primary">
            Prompt
          </Button>
          <Input
            onChange={e => setEvalResponse(e.target.value)}
            style={{ flex: 0.8 }}
            value={evalResponse}
          />
          <Popconfirm
            cancelText="Send as Lead"
            description="Are you sure you want to send to lead?"
            okText="Send as Agent"
            onCancel={() => void send(true)}
            onConfirm={() => void send(false)}
            title="Send text"
          >
            <Button
              icon={<SendOutlined />}
              loading={sendLoading}
              type="primary"
            ></Button>
          </Popconfirm>
        </Flex>
      </Spin>
      <Modal
        onCancel={() => setIsModalVisible(false)}
        onOk={() => setIsModalVisible(false)}
        open={isModalVisible}
        title={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingRight: '25px',
            }}
          >
            Prompt Modal - LeadId {lead.id}
            <Button
              onClick={() =>
                window.open(
                  crmLink({
                    client_id: lead.client_id,
                    source_id: lead.source_id,
                  }),
                )
              }
              type="primary"
            >
              CRM
            </Button>
          </div>
        }
        width={'90vw'}
      >
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <TextArea
            onChange={e => setSystemPrompt(e.target.value)}
            rows={5}
            value={systemPrompt}
          />
          <Segmented
            onChange={(value: SegmentedValue) =>
              setSelectedPrompt(value as string)
            }
            options={prompt_Options}
            value={selectedPrompt}
          />

          <TextArea onChange={updateCustomPrompt} rows={15} value={prompt} />
          <TextArea
            onChange={e => setPastCommunicationPrompt(e.target.value)}
            rows={10}
            value={pastCommunicationPrompt}
          />
          <Button
            // loading={evalResponse === undefined}
            loading={loadingEval}
            onClick={() => {
              evalPrompt().then().catch(console.log);
            }}
            type="primary"
          >
            Run Prompt
          </Button>
          <TextArea
            onChange={e => setEvalResponse(e.target.value)}
            rows={3}
            value={evalResponse}
          />
        </Space>
      </Modal>
    </div>
  );
};
